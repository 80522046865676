import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { IndexComponent } from './index/index.component';
import { routes } from './routes';
import { NgxCaptchaModule } from 'ngx-captcha';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeEs, 'es');
@NgModule({
  providers: [{ provide: LOCALE_ID, useValue: 'es' }],
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    PagesModule,
    NgxCaptchaModule
  ],
  declarations: [IndexComponent],
  exports: [
    RouterModule
  ]
})

export class RoutesModule { }
