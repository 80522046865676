import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, forkJoin, of } from 'rxjs';

import { catchError, retry, timeout } from 'rxjs/operators';
import {
  environment,
  timeout_services
} from '../../../environments/environment';
import { City } from '../../routes/administrate/class/class.city';
import { State } from '../../routes/administrate/class/class.state';
import { Country } from '../../routes/administrate/class/class.country';
import {
  Company,
  FilterCompany,
  companyLevels,
  CompanyEntry,
  SettingsCompany
} from '../../routes/administrate/class/class.company';
import { User } from '../../routes/administrate/class/class.user';
import { TokenService } from '../../models/services/token.service';
import { Responsews } from '../../routes/administrate/class/class.responsews';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage, StorageBucket } from '@angular/fire/storage';
import { Cities } from '../../routes/administrate/class/class.cities';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: JSON.parse(sessionStorage.getItem('token')),
  //     Accept: 'application/json'
  //   })
  // };
  //
  // headers = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   Authorization: JSON.parse(sessionStorage.getItem('token')),
  //   Accept: 'application/json'
  // });
  city = '';
  idCompany = 0;
  private loggedUser;

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private firestore: AngularFirestore,
    private googleStorage: AngularFireStorage
  ) {
    this.loggedUser = this.token.USER;
    this.city = this.loggedUser.selectedCompany.city.name;
    this.idCompany = this.loggedUser.selectedCompany.id;
  }

  // LIST ALL COMPANIES
  configUrl = environment.urlServer + 'company/v1.0/companies/';
  // LIST ALL CITIES
  configCities = environment.urlServer + 'types/v1.0/cities';
  // LIST ALL COUNTRIES
  configCountries = environment.urlServer + 'company/v1.0/getCountries';
  // LIST ALL STATES
  configStates = environment.urlServer + 'company/v1.0/getStates';
  // ADD / UPDATE COMPANY
  configAdd = environment.urlServer + 'company/v1.0/company/';
  // ADD OR UPDATE LEVELS AND COMPANY
  configAddLevelsCompany =
    environment.urlServer + 'PassengerLevel/v1.0/parallels';
  // LIST PARALLELS BY ID OF COMPANY
  configListParalelsByCompany =
    environment.urlServer + 'PassengerLevel/v1.0/parallel/byIdCompany/';
  // LIST GATES BY ID OF COMPANY
  configListCompanyEntries =
    environment.urlServer + 'gate/v1.0/gates/byIdCompanySC/';
  //UPDATE ENABLE SECTOR COMPANY
  EnableSectorCompany =
    environment.urlServer + 'company/v1.0/updateenableSectorCompany/';
  //LIST ENABLE SECTOR COMPANY
  listEnableSectorCompany =
    environment.urlServer + 'company/v1.0/enableSectorCompany/';
  // LIST ALL GATES
  configAddCompanyEntries = environment.urlServer + 'gate/v1.0/gates/';
  // GET USER BY ID OF COMPANY
  configListUsersByCompany = environment.urlServer + 'user/v1.0/byIdCompaby/';
  // CHANGE USER PASSWORD 
  configChangePassword = environment.urlServer + 'user/v1.0/resetPasswordEmail/'
  // ADD / UPDATE COMPANY
  configDisableCompany = environment.urlServer + 'company/v1.0/disableCompany';
  // CREATE NEW COUNTRY
  configCreateCountry = environment.urlServer + 'company/v1.0/saveCountry';
  // CREATE NEW STATE
  configCreateState = environment.urlServer + 'company/v1.0/saveState';
  // CREATE NEW CITY
  configCreateCity = environment.urlServer + 'company/v1.0/saveCity';
  // GET ALL USER IN THE SYSTEM MI BUSETA
  configGetAll = environment.urlServer + 'user/v1.0/getAllUsers';
  // Upload File Terminos y Condiciones to load in web app and movil app
  urlUploadFileTC = environment.urlServer + 'company/v1.0/company/addTerminosCondiciones'
  // Upload File Precio de uso de transporte to load in web app and movil app
  urlUploadFilePTU = environment.urlServer + 'company/v1.0/company/addFilePriceTransport'
  // ------------------------ TESTING ONLY ---------------------------------
  // -----------------------------------------------------------------------
  // -----------------------------------------------------------------------
  // -----------------------------------------------------------------------
  // -----------------------------------------------------------------------
  // -----------------------------------------------------------------------
  // -----------------------------------------------------------------------
  // ENVIAR EL HEADER EN UN HTTP-RESPONSE
  // EJEMPLO:
  // --------------------- GET ALL COMPANIES -------------------------------
  getAllCompanies_with_header_http_response(): Observable<HttpResponse<object[]>> {
    return this.http
      .get<object[]>(this.configUrl, { observe: 'response' })
  }

  // Obtener el estado del sector de la compañía
  getEnableSectorCompany(idCompany: number): Observable<any> {
    return this.http.get<any>(`${this.listEnableSectorCompany}${idCompany}`);
  }

  // Actualizar el estado del sector de la compañía
  updateEnableSectorCompany(idCompany: number, sectorCompany: boolean): Observable<any> {
    return this.http.put<any>(
      `${this.EnableSectorCompany}${idCompany}/${sectorCompany}`,
      {},
      { responseType: 'text' as 'json' } // Esto indica que la respuesta será texto y no JSON
    );
  }


  save_settings_company() {
    return this.firestore
      .collection('settings')
      .doc('idCompany_' + this.idCompany);
  }

  get_settings_company() {
    return this.firestore
      .collection('settings')
      .doc('idCompany_' + this.idCompany)
      .get();
  }

  get_settings_vouchers() {
    if (!this.idCompany) {
        console.error("Error: idCompany no está definido.");
        return of(null);
    }
    return this.firestore
        .collection('settings')
        .doc('idCompany_' + this.idCompany)
        .snapshotChanges(); // 🔥 Escucha cambios en tiempo real
}


  getLoginReport() {
    return this.firestore
      .collection(this.city)
      .doc("Bitacora")
      .collection("fechas", (ref) => ref.where("idcompany", "==", this.loggedUser.selectedCompany.id)).snapshotChanges();
  }

  uploadTerminosCondiciones(file: File) {
    const url = this.urlUploadFileTC + `?idCompany=${this.loggedUser.selectedCompany.id}&nameCompany=${this.loggedUser.selectedCompany.companyName}`;
    const formData = new FormData();
    formData.append('uploadedInputStream', file, file.name);
    return this.http.post(url, formData)
  }

  uploadPriceTransportFile(file: File) {
    const url = this.urlUploadFilePTU + `?idCompany=${this.loggedUser.selectedCompany.id}&nameCompany=${this.loggedUser.selectedCompany.companyName}`;
    const formData = new FormData();
    formData.append('uploadedInputStream', file, file.name);
    return this.http.post(url, formData)
  }

  async getFileTerminosCondiciones() {
    const idCompany = this.loggedUser.selectedCompany.id;
    const companyName: string = this.loggedUser.selectedCompany.companyName;
    const urlTC = `
    https://storage.googleapis.com/mi-buseta-357902.appspot.com/fileTerminosCondiciones/${idCompany}/terminosCondiciones_${companyName.trim()}.pdf`;
    const fileExists = this.googleStorage.storage.app.storage('gs://mi-buseta-357902.appspot.com').ref(`/fileTerminosCondiciones/${idCompany}`);

    return fileExists.listAll().then(a => {
      if (a.items.length > 0) {
        return urlTC;
      } else {
        return "No File";
      }
    });

  }

  async getFilePriceTransportUsage() {
    const idCompany = this.loggedUser.selectedCompany.id;
    const companyName: string = this.loggedUser.selectedCompany.companyName;
    const urlPTU = `
    https://storage.googleapis.com/mi-buseta-357902.appspot.com/filePriceTransportUsage/${idCompany}/priceTransportUsage_${companyName.trim()}.pdf`;
    const fileExists = this.googleStorage.storage.app.storage('gs://mi-buseta-357902.appspot.com').ref(`/filePriceTransportUsage/${idCompany}`);

    return fileExists.listAll().then(a => {
      if (a.items.length > 0) {
        return urlPTU;
      } else {
        return "No File";
      }
    });

  }
  // getAllCompanies_with_header_http_response(): Observable<HttpResponse<object[]>> {
  //   const headers = this.headers;
  //   console.log(headers);
  //   return this.http
  //       .get<object[]>(this.configUrl, {observe: 'response', headers});
  // }
  //
  // // -----------------------------------------------------------------------
  // // ENVIAR EL HEADER SIN EL HTTP-RESPONSE
  // // EJEMPLO:
  // // --------------------- GET ALL COMPANIES -------------------------------
  // getAllCompanies_with_header_only_observable(): Observable<object[]> {
  //   return this.http.get<object[]>(this.configUrl).pipe(
  //       timeout(timeout_services),
  //       retry(3), // retry a failed request up to 3 times
  //       catchError(this.handleError) // then handle the error
  //   );
  // }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------

  // --------------------- GET ALL COMPANIES -------------------------------
  getAllCompanies(): Observable<HttpResponse<object[]>> {
    return this.http
      .get<object[]>(this.configUrl, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  // -----------------------RETURN COMPANY BY COMPANY-ID------------
  getCompanyId(id): Observable<HttpResponse<Company>> {
    const configbyID = this.configAdd + id;
    return this.http.get<Company>(configbyID, { observe: 'response' }).pipe(
      timeout(timeout_services),
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  get_list_all_company(iduser: any): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        environment.urlServer + 'paymenthistory/v1.0/getCompanyActiveByUserID/' + iduser,
        { observe: 'response' }
      );
  }

  // ---------------------  GET ALL LIST OF USERS BY ID-COMPANY  -------------------------------
  getUsersByIdCompany(id): Observable<HttpResponse<object[]>> {
    const newUrlWithCompanyID = this.configListUsersByCompany + id;
    return this.http
      .get<object[]>(newUrlWithCompanyID, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  getAllUsers(): Observable<HttpResponse<Responsews>> {
    const newUrlCompany = this.configGetAll;
    console.log("en metod");

    return this.http.get<Responsews>(newUrlCompany, { observe: 'response' });
  }

  changeUserPassword(email: string, oldPassword: string, newPassword: string): Observable<HttpResponse<Responsews>> {
    const body = new HttpParams()
      .set("email", email)
      .set("oldpassword", oldPassword)
      .set("password", newPassword);

    const headersc = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.http.post<HttpResponse<Responsews>>(this.configChangePassword, body, {
      headers: headersc,
    });
  }

  // -----------------------RETURN ENTRIES BY COMPANY ID------------
  getEntriesbyCompanyId(id): Observable<CompanyEntry[]> {
    const configbyID = this.configListCompanyEntries + id;
    // console.error(configbyID)
    // console.log(configbyID)
    return this.http.get<CompanyEntry[]>(configbyID).pipe(
      timeout(timeout_services),
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  // ----------------- GET LEVELS OF COMPANY BY COMPANY-ID ----------------------
  getLevelsByCompanyId(id): Observable<HttpResponse<companyLevels[]>> {
    const configbyID = this.configListParalelsByCompany + id;
    // console.log(configbyID)
    return this.http
      .get<companyLevels[]>(configbyID, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


  // ----------------------- GET ALL CITIES IN BACKEND ----------------
  getAllCities(): Observable<HttpResponse<Cities[]>> {
    return this.http
      .get<Cities[]>(this.configCities, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }
  // ----------------------- GET ALL COUNTRIES IN BACKEND ----------------
  getAllCountries(): Observable<HttpResponse<Responsews>> {
    return this.http
      .get<Responsews>(this.configCountries, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }
  // ----------------------- GET ALL STATES IN BACKEND ----------------
  getAllStates(): Observable<HttpResponse<Responsews>> {
    return this.http
      .get<Responsews>(this.configStates, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  // CREATE NEW COUNTRY
  createcountry(country: Country): Observable<Responsews> {
    return this.http.post<Responsews>(this.configCreateCountry, country, {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  // CREATE NEW STATE
  createState(state: State): Observable<Responsews> {
    return this.http.post<Responsews>(this.configCreateState, state, {
      headers: { 'Content-Type': 'application/json' },
    })
  }
  // CREATE NEW city
  createCity(city: City): Observable<Responsews> {
    return this.http.post<Responsews>(this.configCreateCity, city, {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  // FROM: ITEM START INDEX; AMOUNT - AMOUNT OF ITEMS TO RETURN; FOLTER: STRING THAT GONNA FILTER -------------------
  getForSearchFIlterPaginationCompany(
    from,
    amount,
    filter
  ): Observable<FilterCompany> {
    const configUrlSearch =
      this.configUrl +
      'forSearch?from=' +
      from +
      '&amount=' +
      amount +
      '&filter=' +
      filter;
    return this.http.get<FilterCompany>(configUrlSearch).pipe(
      timeout(timeout_services),
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  // ------------------- UPDATE LEVELS OF THE COMPANY --------------------
  // Método put cambiado por método posst
  updateLevelsofCompany(companyLevelsPayload: companyLevels[]
  ): Observable<companyLevels[]> {
    return this.http
      .post<companyLevels[]>(
        this.configAddLevelsCompany + '/update',
        companyLevelsPayload,
      )
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  create_product_weconnect(
    data: any
  ): Observable<HttpResponse<any>> {
    return this.http
      .post<any>(
        environment.urlServer + 'typeCost/v1.0/product/create',
        data,
        { observe: 'response' }
      );
  }

  // -------------------ADD NEW COMPANY--------------------
  addCompany(company: Object): Observable<Object> {
    return this.http
      .post<Object>(this.configAdd, company)
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // -------------------  ADD ENTRIES ARRAY -----------------
  addEntriesOfCompany(
    companyEntry: CompanyEntry[]
  ): Observable<CompanyEntry[]> {
    return this.http
      .post<CompanyEntry[]>(
        this.configAddCompanyEntries,
        companyEntry,
      )
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // ------------------- UPDATE ARRAY ENTRIES OF COMPANY (ADD OR UPDATE IF THE COMPANY DONT HAVE A ID) -----------------
  // Metodo cambiado de put a post
  updateEntriesOfCompany(
    companyEntry: CompanyEntry[]
  ): Observable<CompanyEntry[]> {
    // console.log(this.configAddCompanyEntries)
    return this.http
      .post<CompanyEntry[]>(
        this.configAddCompanyEntries + 'update',
        companyEntry,
      )
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // ------------------ UPDATE COMPANY OBJECT -------------
  // Cambio del método de put a post
  updateCompany(company: Object): Observable<Object> {
    return this.http
      .post<Object>(this.configAdd + 'update', company)
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Disable company
  disableCompany(company: Company): Observable<Object> {
    return this.http
      .put<Object>(`${this.configDisableCompany}/${company.id}`, {})
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // ----------------- SERVICE ADD'S COMPANY AND LEVELS OF THIS NEW COMPANY ----------------------
  addLevelsAndCompany(companylevels: companyLevels): Observable<companyLevels> {
    return this.http
      .post<companyLevels>(
        this.configAddLevelsCompany,
        companylevels,
      )
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  get_all_type_cost(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        environment.urlServer + 'typeCost/v1.0/findAllTypeCost/',
        { observe: 'response' }
      );
  }

  get_all_product(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        environment.urlServer + 'typeCost/v1.0/findProduct',
        { observe: 'response' }
      );
  }

  // ------------------ ERROR -------------------------
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
